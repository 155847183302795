import { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const DescriptionEditor = ({ onChange, placeholder, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    if (onChange) {
      onChange(htmlContent);
    }
  };
  // state = {
  //   value: RichTextEditor.createEmptyValue()
  // }

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const content = ContentState.createFromBlockArray(blocksFromHTML);
      const editorState = EditorState.createWithContent(content);
      setEditorState(editorState);
    }
  }, []);

  return (
    <Editor
      editorState={editorState}
      placeholder={placeholder}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleEditorChange}
    />
    // <div>
    //   <ReactQuill
    //     value={editorState}
    //     onChange={handleEditorChange}
    //     theme="snow" // Specify the theme, 'snow' is the default
    //     placeholder="Start writing here..."
    //   />
    //   <div style={{ marginTop: "20px" }}>
    //     <h4>Preview</h4>
    //     <div dangerouslySetInnerHTML={{ __html: editorState }} />
    //   </div>
    // </div>
  );
};

export default DescriptionEditor;
